
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'

// Display html from richText json
export default {
  props: {
    richText: {
      type: Object,
      required: true,
    },
    classNames: {
      type: String,
      default: '',
    },
  },
  computed: {
    html() {
      return documentToHtmlString(this.richText)
    },
  },
}
